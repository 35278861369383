<template>
  <div class="login-container">
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
      auto-complete="on"
      label-position="left"
    >
      <div class="title-container">
        <h3 class="title">登陆页</h3>
      </div>

      <el-form-item prop="username">
        <span class="svg-container">
          <svg-icon name="user" size="14" color="#fff" />
        </span>
        <el-input
          ref=" username"
          v-model="loginForm.username"
          placeholder="请输入用户名"
          name="username"
          type="text"
          tabindex="1"
          auto-complete="on"
        />
      </el-form-item>

      <el-form-item prop="password">
        <span class="svg-container">
          <svg-icon name="password" size="14" color="#fff" />
        </span>
        <el-input
          :key="passwordType"
          ref="password"
          v-model="loginForm.password"
          :type="passwordType"
          placeholder="请输入密码"
          name="password"
          tabindex="2"
          auto-complete="on"
          @keyup.enter.native="handleLogin"
        />
        <span class="show-pwd" @click="showPwd">
          <svg-icon
            :name="passwordType === 'password' ? 'eye' : 'eye-open'"
            size="14"
            color="#fff"
          />
        </span>
      </el-form-item>

      <el-button
        :loading="loading"
        type="primary"
        style="width: 100%; margin-bottom: 30px"
        @click.native.prevent="handleLogin"
        >登 录</el-button
      >
    </el-form>
  </div>
</template>

<script>
import { getLoginRole, login } from "../utils/api.js";
import { clearToken } from "../utils/common";
import { ElMessage } from "element-plus";
export default {
  components: {},
  props: {},
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
      },
      loginRules: {
        username: [{ required: true, trigger: "blur", message: "请输入用户名称" }],
        password: [{ required: true, trigger: "blur", message: "请输入用户密码" }],
      },
      loading: false,
      passwordType: "password",
    };
  },
  watch: {},
  computed: {},
  methods: {
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    handleLogin() {
      login({
        params: [this.loginForm.username, this.loginForm.password],
      })
        .then((res) => {
          if (!res.error && res.result.length > 0) {
            window.localStorage.setItem("systemToken", res.result[0]);
            window.localStorage.setItem("username", this.loginForm.username);

            getLoginRole().then((res2) => {
              if (!res2.error) {
                window.sessionStorage.setItem("userRole", JSON.stringify(res2.result[0]));
                window.sessionStorage.setItem("userType", JSON.stringify(res2.result[1]));
                if (res2.result[1] == 0) {
                  //0-用户界面，1-系统界面，2-代理商界面
                  this.$router.push("/home");
                } else if (res2.result[1] == 1 || res2.result[1] == 2) {
                  this.$router.push("/equipmentList");
                }
              }
            });
          }
        })
        .catch((error) => {
          ElMessage({
            message: "账号或密码输入有误！",
            type: "error",
            customClass: "mzindex",
          });
        });
    },
  },
  created() {
    clearToken();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #fff;
$cursor: #fff;
/* reset element-ui css */
.login-container {
  width: 100%;
  height: 100vh;
  background-image: url(../assets/image/login_bg.jpeg);
  background-size: 1920px 1080px;
  background-position: center center;
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      width: 100%;
      height: 100%;
      background-color: transparent !important;
      border: 0px;
      color: #fff !important;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  /deep/ .el-input__wrapper {
    width: 100%;
    height: 100%;
    box-shadow: none !important;
    color: #fff;
    background-color: transparent !important;
  }
  /deep/ .el-input__inner {
    color: #333 !important;
  }
  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
    margin-bottom:28px;
  }
}
</style>

<style lang="scss" scoped>
$bg: #f0f3f9;
$dark_gray: #fff;
$light_gray: #333;

.login-container {
  min-height: 100%;
  width: 100%;
  background-size: 100% 100%;
  background-position: center center;
  overflow: hidden;

  .login-form {
    position: fixed;
    width: 360px;
    height: 300px;
    background:#fff;
    padding: 60px 35px 0;
    overflow: hidden;
    top: 50%;
    right:100px;
    transform: translate(0%, -60%);
    border-radius: 10px;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
    text-align: center;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}
</style>
